@import '../../settings/mixins';

.reviews {
    h1 {
        text-align: center;
    }

    .slick-next {
        right: 25%;
        top: auto;
        bottom: -11px;

        @include breakpoint(sm) {
            top: 50%;
            right: 20px;
            bottom: auto;
        }
    }

    .slick-prev {
        left: 25%;
        top: auto;
        bottom: -11px;

        @include breakpoint(sm) {
            top: 50%;
            left: 20px;
            bottom: auto;
        }
    }
}

p.review {
    padding-bottom: 0;
    a {
        color: #000;
    }
    em {
        padding: 20px 0;
        display: inline-block;
    }
}
