@import "../../settings/settings";

.navigation {
    background-color: $dark-background;
    color: #fff;
    width: 100%;
}

.sticky {
    z-index: 999;
}

.menu-items {
    display: flex;
    justify-content: space-evenly;
    max-width: 800px;
    margin: auto;
    height: 35px;
    align-items: center;
    @include breakpoint(sm) {
        height: 60px;
    }
    a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-family: $secondary-font;
        font-weight: 400;
        transform: translateY(1px);
        font-size: 14px;

        @include breakpoint(xs) {
            font-size: 16px;
        }

        @include breakpoint(sm) {
            font-size: 20px;
            font-weight: 700;
        }

        &.current {
            color: $highlight-color;
        }
    }
}