@import '../../settings/mixins';
.gallery {
    overflow: hidden;
    padding-bottom: 25px;
    h1 {
        text-align: center;
        margin-bottom: 30px;
    }

    .slide {
        height: auto;
    }

    .slick-slider {
        width: 100%;
        max-width: 800px;
        margin: auto;
    }

    .slick-dots  {
        display: none !important;
        li button:before {
            color: #fff;
        }

        @include breakpoint(sm) {
            bottom: -15px;
            display: block !important;
        }
    }

    .slick-prev {
        left: 10px;

        @include breakpoint (med) {
            left: -25px;
        }
    }

    .slick-next {
        right: 10px;

        @include breakpoint (med) {
            right: -25px;
        }
    }

    .slick-next, .slick-prev {
        &:before {
            color: #fff;
            opacity: 1;
        }
    }
}
.slide {
    height: 400px;
    @include breakpoint(med) {
        height: 600px;
	}
}

.photographer-credits {
    max-width: 800px;
    padding: 0 20px;
    margin: 55px auto 0 auto;

    h3 {
        margin-bottom: 20px;
    }


    .photographer-credits-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    a {
        color: #fff;
        margin-right: 15px;
        white-space: nowrap;
        font-size: 13px;
    }
}