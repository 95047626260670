@import "./settings/settings";


body {
    overflow-x: hidden;
}

section {
    padding: 40px 0;
    color: $dark-font-color;
}

.content-container {
    margin: auto;
    max-width: 1280px;
    padding: 0 10px;
}

.two-column-text {
    max-width: 700px;

    p {
        padding-bottom: 10px;
        margin: auto;
    }

    @include breakpoint(sm) {
        display: flex;
        padding: 30px 0;
        p {
            padding-right: 15px;

            &:nth-child(even) {
                padding-right: 0;
                padding-left: 15px;
            }
        }
	}
}

.centered-text p {
    max-width: 600px;
    text-align: center;
    padding: 40px 0;
    margin: auto;
}

.no-padding {
    padding: 0;

    .content-container {
        padding: 0;
    }
}

.dark-background {
    background-color: $dark-background;
    color: $light-font-color;
}

.med-background {
    background: #6b4d8c;
    background: -moz-linear-gradient(-45deg, #6b4d8c 0%, #261754 100%);
    background: -webkit-linear-gradient(-45deg, #6b4d8c 0%,#261754 100%);
    background: linear-gradient(135deg, #6b4d8c 0%,#261754 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b4d8c', endColorstr='#261754',GradientType=1 );
    color: $light-font-color;
}

.contact {
    h1 {
        text-align: center;
    }
}

.video-container {
    position: relative;
    height: 56.25vw;
    max-height: 530px;
    padding: 0;

    a {
        width: 100%;
        height: 100%;
        display: block;
    }

    @include breakpoint(med) {
        position: relative;
        height: 40.25vw;
        min-height: 400px;
    }

    @include breakpoint(lg) {
        position: relative;
        height: 36.25vw;
        min-height: 400px;
        max-height: 650px;
    }
}

.vimeo-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 75px);
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    background-color: $dark-background;
 }

 .vimeo-wrapper iframe {
    width: 101%;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
 }