@import '../../settings/mixins';

.event {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    @include breakpoint(sm) {
        flex-direction: row;
        &:nth-child(even) {
            flex-direction: row-reverse;
        }
	}
}

.event-copy, .event-image {
    width: 100%;
    @include breakpoint(sm) {
        width: 50%;
	}
}

.event-copy {
    padding: 15px 10px 35px 10px;

    h3 {
        margin: 15px 0;
    }

    @include breakpoint(sm) {
        padding: 0;

        h3 {
            width: 100%;
            padding-left: 10px;
            font-size: 20px;
        }
    }

    @include breakpoint(med) {
        h3 {
            padding-left: 20px;
        }
	}
}

.event-two-column-text {
    p {
        &:nth-child(2) {
            display: none;
        }
    }

    @include breakpoint (sm) {
        p {
            padding: 10px;
        }
    }

    @include breakpoint(med) {
        display: flex;
        flex-wrap: wrap;
        p {
            display: inline-block;
            width: 50%;
            padding: 0 20px;
            font-size: 15px;
        }
        p:nth-child(2) {
            display: inline-block;
        }
    }
}
.event-image {
    font-size: 0;
    img {
        width: 100%;
        padding: 0;
    }
}