@import '../../settings/mixins';

.awards-images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    max-width: 490px;
    margin: 30px auto 0 auto;

    @include breakpoint(lg) {
        max-width: 800px;
    }
}

.wedding-wire-award {
    max-width: 124px;
    margin-bottom: 15px;
}

p.more-reviews {
    padding: 0;
    color: #fff;

    a {
        color: #fff;
        padding-right: 10px;
    }
}