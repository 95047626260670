* {
	margin: 0;
	padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

em {
	font-weight: bold;
}
