@import '../../settings/settings';

.services {
    h1 {
        font-size: 36px;
        text-align: center;
    }

    h2 {
        display: none;

        @include breakpoint(med) {
            font-size: 20px;
            text-align: center;
            margin-top: 15px;
            display: block;
        }
    }
}


.services-list {
    margin: 30px 30px 0 30px;

    li {
        list-style: none;
        font-family: $secondary-font;
        text-align: left;
        margin-bottom: 15px;
    }

    img {
        max-height: 30px;
        padding-right: 15px;
        position: relative;
        top: 9px;
    }

    @include breakpoint(sm) {
        li {
            width: 45%;
            display: inline-block;
        }
    }

    @include breakpoint(med) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        max-width: 1000px;
        margin: 50px auto 0 auto;

        img {
            display: block;
            margin: auto;
            padding-bottom: 15px;
            padding-right: 0;
            max-height: none;
            position: static;
        }

        li {
            text-align: center;
        }

        .break:before {
            content: "\A";
            white-space: pre;
        }
    }
}

@include breakpoint(med) {
    .eventProduction {
        width: 34px;
    }
    .DJEmcee {
        width: 41px;
    }
    .soundSystem {
        width: 69px;
    }
    .lightingDesign {
        width: 48px;
    }
    .entertainment {
        width: 61px;
    }
    .timeManagement {
        width: 36px;
    }
    .eventDesign {
        width: 60px;
    }
}