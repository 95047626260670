@mixin breakpoint($break) {
  $xs: 375px;
  $sm: 768px;
  $med: 1024px;
  $lg: 1320px;

	@if $break == xs {
		@media (min-width: $xs) {
			@content;
		}
	}

	@if $break == sm {
		@media (min-width: $sm) {
			@content;
		}
	}

	@else if $break == med {
		@media (min-width: $med) {
			@content;
		}
	}

	@else if $break == lg {
		@media (min-width: $lg) {
			@content;
		}
	}

	@else if $break == xl {
		@media (min-width: $xl) {
			@content;
		}
	}
}


@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin transform ($transforms) {
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin filter ($filters) {
    -webkit-filter: $filters;
    -moz-filter: $filters;
    -o-filter: $filters;
    -ms-filter: $filters;
    filter: $filters;
}

@mixin transition ($value...) {
    -webkit-transition: $value;
    transition: $value;
}
